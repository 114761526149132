<script setup>
import ChatbotFooter from './ChatbotFooter.vue';
import ChatbotHeader from './ChatbotHeader.vue';
import ChatbotBody from './ChatbotBody.vue'
import {state} from '../store.js';
import { onMounted , ref, defineProps} from 'vue';

const props = defineProps(['close'])
const isLoading = ref(true);

const getHistory = () => {
    frappe.call({
        method:'silicon_ioi.ioi_chatbot.api.chatbot.get_history'
    }).then(r => {
        state.messages = r.message.messages
        state.settings = r.message.settings
    })
}

const send = (message) => {
    addMessage(message)
    frappe.call({
        method:'silicon_ioi.ioi_chatbot.api.chatbot.ask',
        args: {
        'message': message
        }
    }).then(r => {
        r.message.forEach(result => addMessage(result, false))
    })
}

const addMessage = (content, isFromUser = true) => {
    state.messages.push({'content': content, 'isFromUser': isFromUser})
    if (!isFromUser && speechSynthesis && state.settings.enable_tts){
        answerToSpeech(content)
    }
}

const removeHtmlTags = (message) => {
    let div = document.createElement("div")
    div.innerHTML = message
    return div.innerText
}

const findVoice = (name) => {
    let voice = null;
    const voices = window.speechSynthesis.getVoices()
    for(let v of voices){
        if(v.name == name){
            return v
        }
    }
    return voice
}

const answerToSpeech = (message) => {
    let sanitizedMessage = removeHtmlTags(message)
    let utterance = new SpeechSynthesisUtterance(sanitizedMessage)
    utterance.lang = state.settings.lang
    let voice = findVoice(state.settings.voice)
    if (voice){
        utterance.voice = voice;
        utterance.rate = 1.1;
    }
    speechSynthesis.speak(utterance)
}

onMounted(() => {
    isLoading.value = true;
    getHistory();
    isLoading.value = false;
})

</script>

<template>
    <div id="chatbot-room" class="floating card mt-4">
        <ChatbotHeader :close="close"/>
        <ChatbotBody :isLoading="isLoading.value"/>
        <ChatbotFooter :send="send"/>
    </div>
</template>