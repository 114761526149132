<script setup>
import ChatbotRoom from './components/ChatbotRoom.vue';
import ChatbotFloatingButton from './components/ChatbotFloatingButton.vue';
import ChatbotNavButton from './components/ChatbotNavButton.vue';
import { onMounted, watch } from 'vue'
import { state } from './store.js';

const open = () => {
    state.isOpen = true;
    const chatApp = $('.chat-element')
    if(chatApp){
        //Create an event with target other than element from navbar, modal or chat-app to trigger frappe-chat close
        const event = jQuery.Event('mouseup')
        event.target = $('#chatbot-app')
        $(document).trigger(event)
    }
}

const close = () => {
    state.isOpen = false
    $('#chatbot-button').toggle(state.showButtons['float-btn'])
}

const shouldClose = (e)  => {
    const message_chat_icon = $(".chat-navbar-icon *")

    if(message_chat_icon && message_chat_icon.is(e.target)){
        return true;
    }

    return false;
}
const shouldShowButtons = ()  => {
    return frappe.call(
        {
            method:'silicon_ioi.ioi_chatbot.api.chatbot.should_show_buttons'
        }).then((r) => {
            return r.message
        }).catch(e => false)
}
onMounted(() => {
        //Move button to navbar
        $('#chatbot-nav-button').prependTo($('header.navbar > .container > .navbar-collapse > ul'))

        shouldShowButtons().then(
            (showButton) => {
                state.settings.showButtons = showButton
                $('#chatbot-nav-button').toggle(showButton['nav-btn'])
                $('#chatbot-button').toggle(showButton['float-btn'])
            }
        )

        $(document).on('mouseup', (e) => {
            if (shouldClose(e)) {
                close()
            }
        });

        close();
    }
)

</script>

<template>
    <div id="chabot-app">
        <ChatbotRoom v-if="state.isOpen" :close="close"></ChatbotRoom>
        <ChatbotFloatingButton v-if="!frappe.is_mobile() && state.settings.enable_float_btn" v-show="state.showButtons['float-btn']" :open="open"></ChatbotFloatingButton>
        <ChatbotNavButton :open="open"></ChatbotNavButton>
    </div>
</template>
