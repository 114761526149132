<script>
export default{
    props: ['send'],
    data(){
        return {
            inputValue:''
        }
    },
    methods:{
        sendMessage(e){
            if(!e.keyCode ||  (e.keyCode && e.keyCode == 13 && !e.shiftKey)){
                e.preventDefault()
                const content = this.inputValue;
                if(content.trim()){
                    this.send(content);
                    this.inputValue = '';
                }
            }
        },/*
        recordVoice(e){
            let speechRecon = window.speechRecognition || window.webkitSpeechRecognition;

            if (speechRecon){
                console.log(speechRecon)
                let recognition = new speechRecon();

                recognition = async function(event){
                    let text = event.results[0][0].transcript;
                    console.log(text)
                }
                
                recognition.start()
            }

        }
    },
    mounted(){
        if (!(window.speechRecognition || window.webkitSpeechRecognition)){
            $("#recordButton").disabled = true;
        }*/
    }
}
</script>

<template>
    <div class="card-footer d-flex align-content-stretch">
        <textarea @keydown="sendMessage" type="text" :placeholder="frappe._('Ask me anything')" v-model="inputValue" class="input-with-feedback form-control" />
        <button @click="sendMessage" class="btn btn-primary btn-sm primary-action" style="margin-left: 5px">
            <div v-html="frappe.utils.icon('right')"/>
        </button>
        <!--button @click="recordVoice" class="btn btn-primary btn-sm primary-action" style="margin-left: 5px">
            <div v-html="frappe.utils.icon('microphone')"/>
        </button-->
    </div>
</template>