<script>
import Icon from './Icon.vue';

export default{
    name:'ChatbotFloatingButton',
    components: {
        Icon
    },
    props: {
        open: {
            type: Function,
            required: true
        }
    },
    methods:{
        openChat(){
            this.open();
        }
    }
}

</script>

<template>
    <button @click="openChat" id="chatbot-button" class="floating btn btn-primary" style="display: none">
        <Icon height="32" width="32" iconName="robot"/>
    </button>
</template>