import { createApp, h } from 'vue';
import App from './App.vue'

$(function(){
    $('body').append($('<div id="chatbot-root"></div>'));
    const ioi_chatbot = createApp(
    {
        render: () => h(App),
    })

    SetVueGlobals(ioi_chatbot)
    ioi_chatbot.mount('#chatbot-root')
})

