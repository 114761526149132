import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = (...args) => ($options.openChat && $options.openChat(...args))),
    id: "chatbot-button",
    class: "floating btn btn-primary",
    style: {"display":"none"}
  }, [
    _createVNode(_component_Icon, {
      height: "32",
      width: "32",
      iconName: "robot"
    })
  ]))
}