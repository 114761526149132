<script setup>
import {state} from '../store.js'

const openSettings= async () => {
    const me = this;
    const speechSynth = window.speechSynthesis
    const speechSynthIsAvailable = speechSynth !== undefined

    let lang_filter;
    let lang = state.settings.lang;

    if (lang == 'fr'){
        lang_filter = 'fr-FR'
    }else if(lang =='nl'){
        lang_filter = 'nl-NL'
    }else{
        lang_filter = 'en-EN'
    }

    const voices = speechSynthIsAvailable 
        ? speechSynthesis.getVoices().filter(voice => voice.lang.includes(lang_filter))
        : []

    let fields = [
        {
            label: frappe._("Enable floating button"),
            fieldname: 'enable_float_btn',
            fieldtype: 'Check',
            default: state.settings.enable_float_btn
        },
        {
            label:frappe._("Language"),
            fieldname: 'wit_language',
            fieldtype: 'Select',
            options: [
                'FR', 'EN', 'NL'
            ],
            default: state.settings.wit_language
        },/*
        {
            label:frappe._("Enable voice command"),
            fieldname:'enable_voice_cmd',
            fieldtype:'Check',
            read_only:1,
            default: settings.enable_voice_cmd
        },*/
        {
            label: frappe._("Enable text-to-speech response"),
            fieldname: 'enable_tts',
            fieldtype: 'Check',
            default: state.settings.enable_tts,
            read_only : speechSynthIsAvailable ? 0 : 1,
            description: speechSynthIsAvailable ? "" : frappe._("Sorry, speech synthesis is currently not available on your browser")
        },
        {
            label: frappe._("Voice"),
            fieldname: 'voice',
            fieldtype: 'Select',
            options:  voices.map(v => v.name),
            default: state.settings.voice ?? "Default",
            read_only : speechSynthIsAvailable ? 0 : 1,
            description: speechSynthIsAvailable ? "" : frappe._("Sorry, speech synthesis is currently not available on your browser")
        }
]


    const dialog = new frappe.ui.Dialog({
        title: frappe._("Master ioi Settings", null,"Chatbot"),
        fields:fields,
        size: 'small',
        primary_action_label: frappe._('Save'),
        primary_action(values){
            dialog.hide();
            for(const [key,value] of Object.entries(values)){
                state.settings[key] = value
            }
            frappe.call('silicon_ioi.ioi_chatbot.api.chatbot.set_settings', values)
        }
    })

    dialog.show();
}
</script>

<template>
    <button @click="openSettings" class="btn btn-default btn-sm ellipsis">
        <div v-html="frappe.utils.icon('setting-gear')"/>
    </button>
</template>