import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "chabot-app" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.state.isOpen)
      ? (_openBlock(), _createBlock($setup["ChatbotRoom"], {
          key: 0,
          close: $setup.close
        }))
      : _createCommentVNode("v-if", true),
    (!_ctx.frappe.is_mobile() && $setup.state.settings.enable_float_btn)
      ? _withDirectives((_openBlock(), _createBlock($setup["ChatbotFloatingButton"], {
          key: 1,
          open: $setup.open
        }, null, 512 /* NEED_PATCH */)), [
          [_vShow, $setup.state.showButtons['float-btn']]
        ])
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ChatbotNavButton"], { open: $setup.open })
  ]))
}