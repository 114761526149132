import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "container",
  class: "card-body chatbot-chat"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.frappe._("Loading...")), 1 /* TEXT */))
      : (_openBlock(), _createBlock($setup["ChatbotMessageList"], {
          key: 1,
          messages: $setup.state.messages
        }, null, 8 /* PROPS */, ["messages"]))
  ], 512 /* NEED_PATCH */))
}