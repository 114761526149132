<script>
import ChatbotMessage from './ChatbotMessage.vue';

export default {
    props: ['messages'],
    components: { ChatbotMessage },
    methods:{
        scrollToBottom() {
            const lastChild = this.$el.querySelector('.messages > li:last-child')
            if(lastChild){
                lastChild.scrollIntoView({behaviour:'smooth'})
            }
        }
    },
    mounted(){
        this.scrollToBottom();
    },
    updated(){
        this.scrollToBottom();
    }
}

</script>

<template>
    <ul class="messages">
        <ChatbotMessage v-for="(message, index) in messages" :key="index" :content="message.content" :isFromUser="message.isFromUser"></ChatbotMessage>
    </ul>
</template>