import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chatbot-room",
  class: "floating card mt-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ChatbotHeader"], { close: $props.close }, null, 8 /* PROPS */, ["close"]),
    _createVNode($setup["ChatbotBody"], {
      isLoading: $setup.isLoading.value
    }, null, 8 /* PROPS */, ["isLoading"]),
    _createVNode($setup["ChatbotFooter"], { send: $setup.send })
  ]))
}