import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "messages" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChatbotMessage = _resolveComponent("ChatbotMessage")

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.messages, (message, index) => {
      return (_openBlock(), _createBlock(_component_ChatbotMessage, {
        key: index,
        content: message.content,
        isFromUser: message.isFromUser
      }, null, 8 /* PROPS */, ["content", "isFromUser"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}