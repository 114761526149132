<script>
import Icon from './Icon.vue';

export default{
    name:'ChatbotNavButton',
    components: {
        Icon,
    },
    props:['open'],
    methods:{
        openChatbot(){
            this.open();
        }
    }
}
</script>

<template>
    <li id="chatbot-nav-button" class="nav-item" style="display: none">
        <button @click="openChatbot" class="chatbot-frappe-chat-button">
            <Icon height="16" width="16" iconName="robot"/>
        </button>
    </li>
</template>