<script>
export default{
    props:['isFromUser','content']
}
</script>

<template>
    <li v-if="isFromUser" class="chat-message user-message">{{content}}</li>
    <li v-else class="chat-message chatbot-message"><div v-html="content"></div></li>
</template>

<style>
.chatbot-message li{
    list-style-type: disc;
    padding: 8px 0 8px 0;
}

.chatbot-message a {
    color: cornflowerblue;
    text-decoration: underline;
}

.chatbot-message li li{
    list-style-type: circle;
    padding: 8px 0 8px 0;
    margin-left: 15px;
}

</style>