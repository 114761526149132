import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chat-message user-message"
}
const _hoisted_2 = {
  key: 1,
  class: "chat-message chatbot-message"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.isFromUser)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString($props.content), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("li", _hoisted_2, [
        _createElementVNode("div", { innerHTML: $props.content }, null, 8 /* PROPS */, _hoisted_3)
      ]))
}