import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: $setup.openSettings,
    class: "btn btn-default btn-sm ellipsis"
  }, [
    _createElementVNode("div", {
      innerHTML: _ctx.frappe.utils.icon('setting-gear')
    }, null, 8 /* PROPS */, _hoisted_1)
  ]))
}