<script setup>
import ChatbotMessageList from './ChatbotMessageList.vue';
import {state} from '../store.js';
import {defineProps} from 'vue';

const props = defineProps(['isLoading'])

</script>

<template>
    <div ref="container" class="card-body chatbot-chat">
        <div v-if="isLoading">{{frappe._("Loading...")}}</div>
        <ChatbotMessageList v-else :messages="state.messages"></ChatbotMessageList>
    </div>
</template>