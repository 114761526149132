import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chatbot-nav-button",
  class: "nav-item",
  style: {"display":"none"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.openChatbot && $options.openChatbot(...args))),
      class: "chatbot-frappe-chat-button"
    }, [
      _createVNode(_component_Icon, {
        height: "16",
        width: "16",
        iconName: "robot"
      })
    ])
  ]))
}