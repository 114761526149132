import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-footer d-flex align-content-stretch" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      onKeydown: _cache[0] || (_cache[0] = (...args) => ($options.sendMessage && $options.sendMessage(...args))),
      type: "text",
      placeholder: _ctx.frappe._('Ask me anything'),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.inputValue) = $event)),
      class: "input-with-feedback form-control"
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [
      [_vModelText, $data.inputValue]
    ]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = (...args) => ($options.sendMessage && $options.sendMessage(...args))),
      class: "btn btn-primary btn-sm primary-action",
      style: {"margin-left":"5px"}
    }, [
      _createElementVNode("div", {
        innerHTML: _ctx.frappe.utils.icon('right')
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createCommentVNode("button @click=\"recordVoice\" class=\"btn btn-primary btn-sm primary-action\" style=\"margin-left: 5px\">\n            <div v-html=\"frappe.utils.icon('microphone')\"/>\n        </button")
  ]))
}