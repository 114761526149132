import { reactive } from 'vue'

export const state = reactive({
    isOpen: false,
    showButtons: {
        'nav-btn':false,
        'float-btn':false
    },
    messages : [],
    settings : {
        lang : 'en',
        voice: '',
        enable_tts: '0',
        enable_float_btn: '0',
        wit_language : 'EN'
    }
})